<template>
  <div class="commodity">

    <div style="background: #ffffff; padding: 20px">
      <!-- <van-swipe @change="onChange">
        <van-swipe-item
          v-for="(item, index) in list.gallery"
          :key="index"
        >
          <img
            :src="item"
            class="w100"
          />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ current + 1 }}/{{ list.gallery.length }}
          </div>
        </template>
      </van-swipe> -->
      <van-swipe @change="onChange">
        <van-swipe-item>
          <img
            :src="list.picUrl"
            class="w100"
          />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ current + 1 }}/{{ 1 }}
          </div>
        </template>
      </van-swipe>
      <div>
        <div>
          <span style="color: #FF64C0; font-size: 24px">{{
            list.retailPrice
          }}</span>
          <span style="color: #FF64C0; font-size: 12px">积分</span>
          <span class="product-amount">市场价 ：￥{{ list.counterPrice }}元</span>
        </div>
        <div class="product-name">
          {{ list.name }}
        </div>

      </div>
    </div>
    <div class="pto-tit">
      发送短信兑换码<span> {{briefCen}} </span>到<span> {{briefTel}} </span>
    </div>
    <div class="pto-tit">
      重要提示：产品如有质量问题或使用咨询，请拨打售后服务线：400-998-7511
      先验货再收货，如有破损请直接拒签。无理由退换。如有任何问题，打售后电话或加售后微信：18957878801
    </div>

    <div class="pro-img item_desc">
      <h4>商品详情</h4>
      <div
        class="item_desc_wrap"
        style="color:#fff"
        v-if="list.detail"
        v-html="htmlDecode(list.detail) "
      ></div>
      <div
        class="item_desc_wrap"
        v-else
        style="text-align: center;"
      >
        <p>无详情</p>
      </div>
      <!-- <div v-html="list.detail"> -->

      <!-- <img
          v-for="(imgs, i) in list.detailImgs"
          :key="i"
          :src="imgs.img"
          class="w100"
        /> -->
      <!-- </div> -->
    </div>

    <div
      class="footer"
      v-if="!queryType"
    >
      <van-goods-action>
        <van-goods-action-icon
          icon="cart-o"
          text="购物车"
          @click="goCart"
        />
        <van-goods-action-icon
          icon="chat-o"
          text="客服"
          @click="onCustomer"
        />

        <van-goods-action-button
          color="#FFA300"
          type="warning"
          @click="goGoods"
          text="加入购物车"
        />
        <van-goods-action-button
          color="#FF5454 "
          type="danger"
          @click="saveGoods"
          text="立即兑换"
        />
      </van-goods-action>

    </div>

    <!-- 商品规格 -->
    <goods-sku
      :skuShow.sync='skuShow'
      :detailMap='detailMap'
      @onBuyClicked='onBuyClicked'
      @onAddCartClicked='onAddCartClicked'
    ></goods-sku>
    <!-- 选择地址 -->
    <address-popup
      :saveAddressShow.sync='saveAddressShow'
      :addressList='addressList'
      :checkGoodls='checkGoodls'
      :smsSubmitType='smsSubmitType'
      @onSaveDetail='onSaveDetail'
      @onSave='onSave'
      @onDelete='onDelete'
      @onAddressSubmit='onAddressSubmit'
    ></address-popup>
    <!-- 登录 -->
    <overlay-view
      :overlayShow.sync='overlayShow'
      @login='login'
    ></overlay-view>
    <!-- 联系客服 -->
    <ser-view :serShow.sync='serShow'></ser-view>
    <!-- //返回顶部 -->
    <back-top></back-top>
    <!-- //立即兑换 -->
    <points-pop
      :integralPopup.sync='integralPopup'
      :retailPrice='retailPrice'
      :checkGoodls='checkGoodls'
      :smsSubmitType='smsSubmitType'
      @onExSubmit='onExSubmit'
    ></points-pop>
    <!-- //登录时加载状态 -->
    <van-overlay
      :show="isCurLOgin"
      class-name='loginOverlay'
    >
      <div class="wrapper">
        <div
          class=""
          style="text-align:center"
        >
          <van-loading
            size="30px"
            color="#fff"
          >登录中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast, Dialog, Notify } from "vant";
import API from "@/api/http";
import areaList from "@/utils/area.js";
import OverlayView from "@/components/overlay-view";
import SerView from "@/components/ser-view";
import BackTop from "@/components/back-top";
import GoodsSku from "@/components/goods-sku";
import AddressPopup from "@/components/address-popup";
import PointsPop from "@/components/points-pop";
import cast from "@/utils/cast";
export default {
  components: {
    OverlayView,
    SerView,
    BackTop,
    PointsPop,
    GoodsSku,
    AddressPopup,
  },
  data() {
    return {
      isCurLOgin: false,
      serShow: false, //联系客服
      areaList, //省市区
      active: "1",
      overlayShow: false, //没登陆时
      integralShow: false, //积分不足，显示提示弹框
      integra: false, //是否积分不足
      list: {
        // product: {
        //   name: "",
        //   pcode: "",
        //   points: "",
        //   facevalue: "",
        //   amount: "",
        //   postage: 0,
        //   attrs: "",
        //   discount: "",
        // },
        retailPrice: "",
        // detailImgs: [],
        detail: "",
        gallery: [],
      },
      saveAddressShow: false,
      addressList: [],
      current: 0, //轮播图数量

      commodityInfo: {}, //当前选择的规格参数
      queryType: "",
      skuShow: false, //规格显示
      integralPopup: false, //立即购买显示
      retailPrice: 0, //当前商品积分值
      detailMap: {}, //当前产品信息
      checkGoodls: {},

      checkAddressId: "",
      smsSubmitType: 2,
      goodId: "", //d当前商品id
      briefCen: "",
      briefTel: "",
    };
  },
  computed: {
    userType() {
      return this.$store.getters["user/userType"];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    phone() {
      return this.$store.state.user.phone;
    },
  },
  created() {
    this.queryType = this.$route.query.type;

    this.getDetail();
  },

  methods: {
    htmlDecode(str) {
      var s = "";

      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, "");

      return s;
    },
    onChange(index) {
      this.current = index;
    },

    getDetail() {
      const obj = {
        id: this.$route.query.id,
      };
      API.getProductsDetail(obj).then((response) => {
        let info = response.data.info;
        this.list = info;

        this.detailMap = response.data;
        let brief = info.brief.split(",");
        this.briefCen = brief[0];
        this.briefTel = brief[1];
      });
    },

    goCart() {
      //  前往购物车
      this.$router.push("/shop");
      this.$store.commit("menu/bottomId", 2);
    },
    onCustomer() {
      //联系客服
      this.serShow = true;
    },
    // 兑换商品选择规格
    goGoods() {
      // 加入购物车
      if (!this.phone) {
        this.overlayShow = true;
        return;
      }
      this.skuShow = true;
    },
    saveGoods() {
      //  立即购买
      if (!this.phone) {
        this.overlayShow = true;
        return;
      }
      this.skuShow = true;
    },
    onIntegra() {
      //立即购买
      this.integra = true;
      this.integralShow = false;
      this.skuShow = true;
    },
    getAddressList(val) {
      API.getAddressList().then((res) => {
        let list = res.data.list;
        this.addressList = list.map((v) => {
          return {
            ...v,
            address: v.province + v.city + v.county,
          };
        });
        if (val) {
          if (list.length > 0) {
            this.saveAddressShow = true;
          } else {
            this.integralPopup = true;
          }
        }
      });
    },
    onSaveDetail(item) {
      //新增地址的保存

      this.getAddressList();
    },
    onSave() {
      //编辑地址的保存

      this.getAddressList();
    },
    onDelete() {
      this.getAddressList();
    },
    onSubmit() {
      if (!this.phoneCode) {
        return Toast("请输入验证码");
      }
      const obj = {
        coid: this.sendPaySmsOrder, // 发送验证码接口返回
        dhcode: this.phoneCode, // 兑换验证码
      };
      API.postOrderPay(obj).then((response) => {
        Toast("兑换成功");
        this.$router.push({
          path: "/exchange",
          query: {
            pid: this.$route.query.id,
          },
        });
      });
    },
    onBuyClicked(data) {
      //商品规格弹窗中的立即购买
      console.log("kk", data);
      this.skuShow = false;
      // this.integralPopup = true;
      this.retailPrice = data.retailPrice;
      this.checkGoodls = data;
      this.goodId = data.productId; //d当前商品id
      //普通用户有地址则先选择地址，然后获取验证码兑换，没有则出现填写地址兑换流程
      //b端用户，有无地址都出现填写地址兑换流程
      if (this.userType === cast.USER_LEVEL0) {
        //普通用户
        this.getAddressList(1);
      } else {
        //b端
        this.integralPopup = true;
      }

      //一种方式
      // this.getAddressList(1);
      //二种方式
      // this.integralPopup = true;
    },
    onAddCartClicked(item) {
      //商品规格弹窗中的加入购物车

      API.addCart(item).then((res) => {
        Toast("加入购物车成功");
      });
    },
    // 获取登录验证码

    login(data) {
      //登录

      if (this.userType !== "") {
        //存在登录记录，再次登录
        //1、如果是渠道用户，调用login_by_phone接口
        //2、如果普通用户，调用login接口
        if (this.userType === cast.USER_LEVEL0) {
          //普通用户
          this.getLogin1(data);
        } else {
          this.getLogin2({ ...data, mobileType: 1 });
        }
      } else {
        //没有登录记录,调用login接口
        this.getLogin1(data);
      }
    },
    getLogin1(data) {
      //没有登录时调用
      // type 1 正常用户 2 渠道用户
      this.isCurLOgin = true;
      this.$store
        .dispatch("user/login", {
          ...data,
          username: "",
          password: "",
          type: 1,
          mobileType: 1,
        })
        .then((res) => {
          Toast("登录成功");
          this.isCurLOgin = false;
          this.overlayShow = false;
        })
        .catch((err) => {
          this.isCurLOgin = false;
          Toast(err.errmsg);
        });
    },
    getLogin2(data) {
      this.isCurLOgin = true;
      this.$store
        .dispatch("user/shopLogin", data)
        .then((res) => {
          Toast.clear();

          Toast("登录成功");
          this.isCurLOgin = false;
          this.overlayShow = false;
        })
        .catch((err) => {
          this.isCurLOgin = false;
          Toast(err.errmsg);
        });
    },
    routerUser() {
      this.$router.push({
        path: "user",
      });
      // this.$router.replace('user')
    },
    routerJump() {
      if (!this.$store.getters.token) {
        this.loginShow = true;
        return;
      }
      this.$router.push({
        path: "shareIndex",
        query: {
          pid: this.$route.query.id,
        },
      });
    },
    protocol() {
      Dialog.alert({
        title: "移动积分商城商品兑换服务协议",
        messageAlign: "left",
        message: `
        <p style="margin:0">在使用移动积分商城商品代兑换服务之前，请您(即用户)仔细阅读下述协议条款。一旦您点击确认本协议，即表示您已接受了以下所述的条款和条件，同意受本协议约束。如果您不同意接受全部的条款和条件，那么您将无法使用本协议约定的技术服务。</p>
        <p style="margin:0">第一条服务说明</p>
        <p style="margin:0">1.1通过桔子网络进行积分商品兑换时，可自由选择自行通过您自有的移动积分商城账户和密码登陆进行兑换，也可选择将您的商城账户和密码授权给桔子网络进行代兑换。</p>
        <p style="margin:0">1.2为了更好地给您提供商品代兑换服务，一旦您选择桔子网络代兑换服务，您同意将您的移动积分商城账户和密码授权给桔子网络，允许桔子网络使用您的移动积分商城账户和密码登陆，并完成商品兑换及其其他相关操作。</p>
        <p style="margin:0">1.3接受此协议，代表自愿委托使用移动积分商城进行商品兑换。</p>
        <p style="margin:0">第二条各方权利和义务</p>
        <p style="margin:0">2.1桔子网络提供的是移动积分商城商品代兑换服务，您接受本协议，意味着您同意我们使用您填写的收货地址进行代兑换，同时您必须遵守移动积分商城相关规定(https://jf.10086.cn)。</p>
        <p style="margin:0">2.2用户了解并同意，桔子网络会根据您的授权使用您的移动积分商城账户及密码操作代兑换事宜，为了后续进一步向您提供服务，也会在系统中记录您的前述相关信息。但是对于您的隐私信息及相关交易信息，桔子网络会严格保护。</p>
        <p style="margin:0">2.3您同意在每次使用本服务并登陆您的移动积分商城账户后将账户中的历史订单同步到桔子网络，用于您进一步的查询和操作</p>
        <p style="margin:0">2.4桔子网络可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议。</p>
        `,
      }).then(() => {
        // on close
      });
    },
    async onExSubmit() {
      this.integralPopup = false;
      // 立即兑换
      // await this.addAddress(data);
      // await this.orderFastSubmit({ chosenAddressId: this.checkAddressId });
    },
    onAddressSubmit() {
      //地址选择弹框的确定兑换
    },
    addAddress(data) {
      //没有地址时，添加的地址调用 地址添加接口，以获取地址id
      const obj = {
        id: 0,
        city: data.city,
        county: data.county,
        province: data.province,
        name: data.name,
        tel: data.tel,
        addressDetail: data.addressDetail,
        isDefault: false,
        areaCode: data.areaCode,
      };
      return API.postAddressAdd(obj).then((res) => {
        this.checkAddressId = res.data;
      });
    },
    orderFastSubmit(data) {
      return API.orderFastSubmit({
        goodId: this.checkGoodls.goodsId,
        addressId: data.chosenAddressId,
        productId: this.checkGoodls.productId,
        number: this.checkGoodls.number,
        iu: utils.getRegionIu(),
      }).then((res) => {
        Toast("兑换成功");
        this.integralPopup = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.commodity {
  background: $primary;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.product-amount {
  color: #d9d9d9;
  font-size: 12px;
  margin-left: 20px;
  text-decoration: line-through;
}
.product-name {
  color: #262626;
  font-size: 18px;
  margin-top: 15px;
  line-height: 25px;
}
.pto-tit {
  background: #ffffff;
  padding: 20px;
  font-size: 12px;
  color: $textColor;
  line-height: 17px;
  margin: 10px 0;
}

.disabled {
  background: #999999;
}

.pro-img {
  background: #ffffff;
  padding: 20px 20px 60px 20px;
  h4 {
    padding-bottom: 10px;
  }
}
.item_desc {
  background-color: #fff;
  ::v-deep p {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }
  ::v-deep img {
    max-width: 100%;
    display: block;
  }
}
</style>
