<template>
  <van-popup
    v-model="show"
    closeable
    round
    @close="close"
    close-icon="close"
    :style="{ padding: '10px 0px', width: '80%'}"
  >
    <div style="width:100%;padding:10px 20px;box-sizing: border-box">
      <div style="text-align:center;font-size:18px">联系客服</div>

      <van-row style="margin-top:30px;font-size:14px">
        <van-col
          span="8"
          offset="1"
          style="color:#8A8A8A"
        >客服电话</van-col>
        <van-col span="10">400-998-0309</van-col>
      </van-row>
      <van-row style="margin-top:20px;font-size:14px">
        <van-col
          span="8"
          offset="1"
          style="color:#8A8A8A"
        >客服微信</van-col>
        <van-col span="10">gewala111</van-col>
      </van-row>

      <div style="text-align:center;margin-top:20px;">
        <van-image
          width="120"
          height="120"
          :src="require('../../assets/image/ewm.png')"
        />
        <p style="color:#8A8A8A;font-size:12px">扫码加入 随时联系客服</p>
      </div>
    </div>
  </van-popup>
</template>

<script>
//联系客服组件
export default {
  props: {
    serShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    serShow(val) {
      this.show = val;
    },
  },
  created() {
    this.show = this.serShow;
  },
  methods: {
    close() {
      this.$emit("update:serShow", false);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
